import React, { Component } from "react";
import "../buildCalculator/Calculator.css";
class Calculator extends Component {
  constructor(props) {
    super(props);

    this.state = {
      input: "0",
      prev: "",
      curr: "",
      total: false,
      operator: "",
    };
  }

  handleInput = (e) => {
    const { curr } = this.state;
    const value = e.target.innerText;
    if (curr.includes(".") && value === ".") return;
    if (value !== ".") {
      this.setState(
        {
          curr: curr + value,
        },
        () => this.componentDidMount()
      );
    } else {
      curr
        ? this.setState(
            {
              curr: curr + value,
            },
            () => this.componentDidMount()
          )
        : this.setState(
            {
              curr: 0 + value,
            },
            () => this.componentDidMount()
          );
    }
  };
  componentDidMount() {
    const { curr, prev, operator } = this.state;
    console.log(curr);
    if (curr !== "") {
      this.setState({ input: prev + operator + curr });
    }
  }
  handleOperator = (e) => {
    const { curr, total, operator } = this.state;

    if (!operator) {
      this.setState({
        curr: "",
        prev: curr,
        operator: e.target.value,
        input: curr + e.target.value,
      });
    }
  };
  handleEqual = (e) => {
    const { operator, prev, curr } = this.state;
    let cal;
    switch (operator) {
      case "+":
        cal = String(parseFloat(prev) + parseFloat(curr));
        break;
      case "-":
        cal = String(parseFloat(prev) - parseFloat(curr));
        break;
      case "x":
        cal = String(parseFloat(prev) * parseFloat(curr));
        break;
      case "/":
        cal = String(parseFloat(prev) / parseFloat(curr));
        break;
      default:
        return;
    }
    this.setState({
      input: prev + operator + curr + e.target.value + cal,
      total: true,
      curr: cal,
      operator: "",
    });
  };
  handleReset = () => {
    this.setState({
      input: "0",
      prev: "",
      curr: "",
      total: false,
      operator: "",
    });
  };

  render() {
    return (
      <div className="containerC">
        <h2>Calculator Using ReactJs</h2>
        <div className="Calc">
          <input
            type="text"
            class="calculator-screen"
            value={this.state.input}
            disabled
          />

          <div class="calculator-keys">
            <button
              type="button"
              class="operator"
              value="+"
              onClick={this.handleOperator}
            >
              +
            </button>
            <button
              type="button"
              class="operator"
              value="-"
              onClick={this.handleOperator}
            >
              -
            </button>
            <button
              type="button"
              class="operator"
              value="x"
              onClick={this.handleOperator}
            >
              &times;
            </button>
            <button
              type="button"
              class="operator"
              value="/"
              onClick={this.handleOperator}
            >
              &divide;
            </button>

            <button type="button" value="7" onClick={this.handleInput}>
              7
            </button>
            <button type="button" value="8" onClick={this.handleInput}>
              8
            </button>
            <button type="button" value="9" onClick={this.handleInput}>
              9
            </button>

            <button type="button" value="4" onClick={this.handleInput}>
              4
            </button>
            <button type="button" value="5" onClick={this.handleInput}>
              5
            </button>
            <button type="button" value="6" onClick={this.handleInput}>
              6
            </button>

            <button type="button" value="1" onClick={this.handleInput}>
              1
            </button>
            <button type="button" value="2" onClick={this.handleInput}>
              2
            </button>
            <button type="button" value="3" onClick={this.handleInput}>
              3
            </button>

            <button type="button" value="0" onClick={this.handleInput}>
              0
            </button>
            <button
              type="button"
              class="decimal"
              value="."
              onClick={this.handleInput}
            >
              .
            </button>
            <button
              type="button"
              class="all-clear"
              value="all-clear"
              onClick={this.handleReset}
            >
              AC
            </button>

            <button
              type="button"
              class="equal-sign"
              value="="
              onClick={this.handleEqual}
            >
              =
            </button>
          </div>
        </div>
        <div>
          <h5>This is created by Rj_Rahul_Tech</h5>
        </div>
      </div>
    );
  }
}
export default Calculator;
